<template>
    <div class="clientcabin clientcabin-msn">
        <div class="blueish">
            <v-container>
                <v-row justify="center">
                    <v-col cols="12" lg="9" xl="7" class="py-6 py-md-10">
                        <div class="headline text-center pb-10 mt-4">
                            Get Published On The
                            <span class="primary--text">9-Figure Traffic</span>
                            Mega-Site MSN.com And Unlock
                            <b>FIVE Huge Organic Traffic Sources</b>
                            Getting
                            <span class="primary--text">
                                Billions Of Visitors
                            </span>
                            Per Month…
                        </div>
                        <p class="text-h5 px-10 text-center">
                            Drive New Leads, Sales & Customers Into Your
                            Business From A Fresh New Source Of Virtually
                            Untapped Traffic
                        </p>
                        <div class="text-h4 text-center py-8">
                            <span class="primary--text">Big News</span>
                            From The Traffic Lab!
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </div>
        <v-container>
            <v-row justify="center">
                <v-col
                    cols="12"
                    lg="9"
                    xl="7"
                    class="py-6 py-md-10 text-subtitle-1"
                >
                    <p>Let’s get straight to the point…</p>
                    <p>
                        A little while ago, I discovered a way to unlock access
                        to billions of monthly visitors, using Microsoft’s
                        MSN.com.
                    </p>
                    <p>
                        I’ve been in the traffic lab for months now, refining
                        the method to make it so simple, anybody can use it.
                    </p>
                    <p>And I finally cracked it.</p>
                    <p>
                        What you’re about to see on this page, lets you tap into
                        several huge and overlooked sources of organic traffic…
                    </p>
                    <p>… you can use it to promote your business or website…</p>
                    <p>… get more visitors, leads and sales…</p>
                    <p>
                        … and it even has the potential for explosive traffic
                        spikes too.
                    </p>
                    <p>
                        It all starts with the strange and important discovery I
                        made…
                    </p>
                    <v-img
                        class="mb-4"
                        src="@/assets/img/clientcabin/msn/1.png"
                    />
                    <p class="text-center text-h6 font-weight-bold px-10">
                        Millions Of Visitors Have Been Driven Using This Classic
                        Traffic Strategy & Microsoft’s MSN.Com Gives Us A New
                        Way To Do It!
                    </p>
                </v-col>
            </v-row>
        </v-container>

        <div class="blueish">
            <v-container>
                <v-row justify="center">
                    <v-col cols="12" lg="9" xl="7" class="py-6 py-md-10">
                        <div class="subheadline text-center py-8">
                            A Source Of
                            <span class="primary--text">
                                8,000,000,000+ Monthly Visitors
                            </span>
                            Was Hiding In Plain Sight!
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </div>

        <v-container>
            <v-row justify="center">
                <v-col
                    cols="12"
                    lg="9"
                    xl="7"
                    class="py-6 py-md-10 text-subtitle-1"
                >
                    <p>
                        Organic search traffic is the best traffic for growing
                        any business.
                    </p>
                    <p>So I’m always looking for ways to get more of it.</p>
                    <p>
                        Google, Bing and Yahoo are the world’s Top 3 search
                        engines.
                    </p>
                    <p>Their search results pages are often very similar…</p>
                    <p>… but with one very notable exception.</p>
                    <p>A huge, multi-billion dollar website:</p>
                    <p class="font-weight-bold">MSN.com</p>
                    <p>Bing and Yahoo LOVE ranking content from MSN.com</p>
                    <p>But Google... does not.</p>
                    <p>It seemed strange at first.</p>
                    <p>
                        MSN.com is a HUGE site with MASSIVE authority, TONS of
                        content and HUNDREDS of MILLIONS of visitors every
                        month.
                    </p>
                    <p>It made me wonder…</p>
                    <p>Why wouldn’t Google want to rank a site like this?</p>
                    <p>Then it clicked…</p>
                    <v-img
                        class="mb-4"
                        src="@/assets/img/clientcabin/msn/2.png"
                    />
                    <div
                        class="text-center text-h6 font-weight-bold px-10 pb-4"
                    >
                        Microsoft & Google Are Fierce Rivals – Which Revealed A
                        Vast And
                        <span class="primary--text">
                            Totally Overlooked Traffic Opportunity
                        </span>
                        For Us!
                    </div>
                    <p>Microsoft owns MSN.com…</p>
                    <p>… AND it owns Bing Search…</p>
                    <p>… AND it owns Bing News…</p>
                    <p>… AND it owns Windows…</p>
                    <p>… AND even Yahoo uses Bing Data!</p>
                    <p>
                        Millions upon millions of people use these platforms
                        every day.
                    </p>
                    <p class="font-weight-bold">
                        So Google just doesn’t want to send traffic to the
                        competition!
                    </p>
                    <p>Makes sense, right?</p>
                    <p class="font-weight-bold">And that gave me an idea…</p>
                    <p>
                        … If Google isn’t responsible for the traffic these
                        sites get…
                    </p>
                    <p>
                        … and they’re getting BILLIONS of visitors each month…
                    </p>
                    <p>
                        … could there be an UNTAPPED traffic opportunity here…
                    </p>
                    <p class="font-weight-bold">
                        … and could MSN.com be the key to unlocking it?
                    </p>
                    <p>
                        After extensive testing, I discovered the answer was
                        “Yes”.
                    </p>
                    <p>(and I even managed to get Google on board too...)</p>
                </v-col>
            </v-row>
        </v-container>

        <div class="clientcabin-darkblue">
            <v-container>
                <v-row justify="center">
                    <v-col
                        cols="12"
                        lg="9"
                        xl="7"
                        class="py-6 py-md-10 white--text"
                    >
                        <div class="subheadline text-center py-8">
                            Experiment #1:
                            <span class="font-weight-regular">
                                Local Real Estate Niche
                            </span>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </div>

        <v-container>
            <v-row justify="center">
                <v-col cols="12" lg="9" xl="7" class="py-6 py-md-10">
                    <v-img
                        class="mb-4"
                        src="@/assets/img/clientcabin/msn/3.png"
                    />
                    <div class="text-center text-h6 font-weight-bold px-10">
                        Google Wants To Hide It; But Our MSN Traffic Strategy
                        Just Grabbed
                        <span class="primary--text">Featured Ranking</span>
                        In Bing Search &
                        <span class="primary--text">#1 In Bing News!</span>
                        (Hint: It’s All About Getting More Clicks!)
                    </div>
                </v-col>
            </v-row>
        </v-container>

        <div class="blueish">
            <v-container>
                <v-row justify="center">
                    <v-col cols="12" lg="9" xl="7" class="py-6 py-md-10">
                        <div class="subheadline text-center py-8">
                            The
                            <span class="primary--text">Enormous</span>
                            (And Little-Known) Traffic Power Of Microsoft's
                            MSN.Com
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </div>

        <v-container>
            <v-row justify="center">
                <v-col
                    cols="12"
                    lg="9"
                    xl="7"
                    class="py-6 py-md-10 text-subtitle-1"
                >
                    <p>
                        In all my time driving traffic and growing businesses
                        online…
                    </p>
                    <p>I can tell you this one thing for sure…</p>
                    <p>You grow a business faster by AVOIDING COMPETITION…</p>
                    <p>
                        By leveraging the ‘untapped’ and ‘overlooked’
                        opportunities…
                    </p>
                    <p>
                        This MSN Traffic Strategy opens up a whole new world of
                        “low competition” traffic for you.
                    </p>
                    <p>
                        While this strategy certainly works to get more Google
                        traffic…
                    </p>
                    <p>
                        The REAL POWER is how you can quickly tap into the vast
                        ocean of traffic from MSN, Bing, Bing News and Yahoo…
                    </p>
                    <p class="font-weight-bold">
                        Just look at the untapped traffic potential here…
                    </p>
                    <v-img src="@/assets/img/clientcabin/msn/4.png" />
                    <div
                        class="text-center text-h6 font-weight-bold px-10 pb-10"
                    >
                        MSN Alone Gets Over
                        <span class="primary--text">700 Million</span>
                        Visitors Per Month…
                    </div>
                    <v-img src="@/assets/img/clientcabin/msn/5.png" />
                    <div
                        class="text-center text-h6 font-weight-bold px-10 pb-10"
                    >
                        Bing & Bing News Serve Over
                        <span class="primary--text">3.1 Billion</span>
                        Visitors Per Month…
                    </div>
                    <v-img src="@/assets/img/clientcabin/msn/6.png" />
                    <div
                        class="text-center text-h6 font-weight-bold px-10 pb-10"
                    >
                        Yahoo Serves Over
                        <span class="primary--text">4.5 Billion</span>
                        Visitors Per Month!
                    </div>
                    <v-img
                        class="mb-4"
                        src="@/assets/img/clientcabin/msn/7.png"
                    />
                    <div
                        class="text-center text-h6 font-weight-bold px-10 pb-10"
                    >
                        PLUS – MSN & Bing Are Also Native To
                        <span class="primary--text">
                            Millions Of Daily Active Windows Users
                        </span>
                        With Apps On Desktop & Mobile Devices Too
                    </div>
                    <p>
                        So you see… Each platform receives millions upon
                        millions of people DAILY.
                    </p>
                    <p class="font-weight-bold">
                        This is SERIOUS traffic that’s almost completely
                        overlooked.
                    </p>
                    <p>And we discovered the “back door” into all of it…</p>
                </v-col>
            </v-row>
        </v-container>
        <div class="blueish">
            <v-container>
                <v-row justify="center">
                    <v-col cols="12" lg="9" xl="7" class="py-6 py-md-10">
                        <div class="subheadline text-center py-8">
                            The
                            <span class="primary--text">Fastest</span>
                            Way To Get Organic Traffic Online Is By Leveraging
                            The
                            <span class="primary--text">Power & Authority</span>
                            Of Existing Brands
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </div>
        <v-container>
            <v-row justify="center">
                <v-col
                    cols="12"
                    lg="9"
                    xl="7"
                    class="py-6 py-md-10 text-subtitle-1"
                >
                    <p>
                        With enough power and authority, it’s MUCH EASIER to get
                        traffic and make more sales.
                    </p>
                    <p>For Example – With Enough Power And Authority:</p>
                    <ul class="mb-4">
                        <li>You can simply publish a blog post…</li>
                        <li>Regular visitors will see it immediately…</li>
                        <li>Search engines will index it quickly…</li>
                        <li>
                            You get more and more organic search visitors over
                            time…
                        </li>
                        <li>And you can do it over and over and over again…</li>
                    </ul>
                    <p>
                        Of course, it takes a LONG time and a LOT of money to
                        build a site like MSN.com…
                    </p>
                    <p class="font-weight-bold">
                        Fortunately, you don’t have to.
                    </p>
                    <p>
                        We found a way to leverage MSN’s existing power and
                        authority, to promote your business.
                    </p>
                    <p>
                        We can even use a special kind of blog post I’ve
                        refined, to grow your traffic more rapidly…
                    </p>
                </v-col>
            </v-row>
        </v-container>
        <div class="clientcabin-darkblue">
            <v-container>
                <v-row justify="center">
                    <v-col
                        cols="12"
                        lg="9"
                        xl="7"
                        class="py-6 py-md-10 white--text"
                    >
                        <div class="subheadline text-center py-8">
                            Experiment #2:
                            <span class="font-weight-regular">
                                eCommerce Eyewear Niche
                            </span>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </div>
        <v-container>
            <v-row justify="center">
                <v-col
                    cols="12"
                    lg="9"
                    xl="7"
                    class="py-6 py-md-10 text-subtitle-1"
                >
                    <v-img
                        class="mb-4"
                        src="@/assets/img/clientcabin/msn/8.png"
                    />
                    <div class="text-center text-h6 font-weight-bold px-10">
                        <span class="primary--text">Top Rankings</span>
                        In Bing News & Search With
                        <span class="primary--text">Featured Snippets</span>
                        Too! All Thanks To A
                        <span class="primary--text">
                            Special Style Of Blog Post
                        </span>
                        & MSN.com
                    </div>
                </v-col>
            </v-row>
        </v-container>
        <div class="blueish">
            <v-container>
                <v-row justify="center">
                    <v-col cols="12" lg="9" xl="7" class="py-6 py-md-10">
                        <div class="subheadline text-center py-8">
                            Tap Into 8 Billion+ Monthly Users With A
                            <div class="primary--text">
                                Special Type Of Blog Post
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </div>
        <v-container>
            <v-row justify="center">
                <v-col
                    cols="12"
                    lg="9"
                    xl="7"
                    class="py-6 py-md-10 text-subtitle-1"
                >
                    <p>
                        The best results I’ve seen come from publishing a
                        special kind of blog post to MSN.com.
                    </p>
                    <p>
                        There’s not enough room to go into great detail here,
                        but it revolves around a proven strategy to get more and
                        more clicks.
                    </p>
                    <p>This special kind of post has the potential to:</p>
                    <ul class="mb-10">
                        <li>Get published on MSN.com</li>
                        <li>Get shown in Bing & Yahoo search results</li>
                        <li>Get picked up by Bing News</li>
                        <li>Get featured on MSN’s homepage</li>
                        <li>Even go viral across the rest of the internet</li>
                    </ul>
                    <v-img
                        class="mb-4"
                        src="@/assets/img/clientcabin/msn/9.png"
                    />
                    <div
                        class="text-center text-h6 font-weight-bold px-10 pb-10"
                    >
                        A Special Type Of Blog Post Has The Potential To
                        Generate
                        <span class="primary--text">
                            Significant Traffic Spikes
                        </span>
                        IN ADDITION To Regular Traffic Scaling
                    </div>
                    <p>
                        It doesn’t happen every time (it doesn’t need to); but
                        we can certainly stack the odds in our favor.
                    </p>
                    <p>
                        The viral potential works as a kind of “added bonus” to
                        an already powerful traffic strategy.
                    </p>
                    <p>
                        Even without it though, the strategy works to
                        consistently stack and scale your traffic, even for the
                        most difficult projects…
                    </p>
                    <p>… a brand new site!</p>
                </v-col>
            </v-row>
        </v-container>
        <div class="blueish">
            <v-container>
                <v-row justify="center">
                    <v-col cols="12" lg="9" xl="7" class="py-6 py-md-10">
                        <div class="subheadline text-center py-8">
                            6,944 Organic Visitors In 72 Days From MSN.Com Even
                            To A
                            <span class="primary--text">Brand New</span>
                            Site!
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </div>
        <v-container>
            <v-row justify="center">
                <v-col
                    cols="12"
                    lg="9"
                    xl="7"
                    class="py-6 py-md-10 text-subtitle-1"
                >
                    <p>
                        Getting organic traffic to a brand new site is
                        notoriously difficult.
                    </p>
                    <p>
                        Because a brand new site has ZERO power and authority.
                    </p>
                    <p>
                        Search engine algorithms rarely give them much
                        attention.
                    </p>
                    <p>
                        So when I saw a brand new site getting almost 7,000
                        visitors in just 72 days from MSN – I realized just how
                        powerful this was…
                    </p>
                    <v-img
                        class="mb-4"
                        src="@/assets/img/clientcabin/msn/10.png"
                    />
                    <div
                        class="text-center text-h6 font-weight-bold px-10 pb-10"
                    >
                        The MSN Strategy Can Drive
                        <span class="primary--text">
                            Significant Traffic DIRECTLY
                        </span>
                        Even To Brand New Websites – No More Waiting Months To
                        Scale!
                    </div>
                    <p>
                        Not only do you have a new and reliable way to scale
                        organic traffic to your website or business…
                    </p>
                    <p>
                        … you now have a way to help even a brand new or
                        undeveloped site get traffic faster – and scale its
                        power and authority sooner.
                    </p>
                    <p>This powerful effect compounds.</p>
                    <p class="font-weight-bold">
                        All you need now is a way to consistently get published
                        on MSN.com and unleash its traffic potential…
                    </p>
                </v-col>
            </v-row>
        </v-container>
        <div class="blueish">
            <v-container>
                <v-row justify="center">
                    <v-col cols="12" lg="9" xl="7" class="py-6 py-md-10">
                        <div class="subheadline text-center py-8">
                            Introducing
                            <span class="primary--text">
                                Microsoft MSN Publishing...
                            </span>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </div>
        <v-container>
            <v-row justify="center">
                <v-col
                    cols="12"
                    lg="9"
                    xl="7"
                    class="py-6 py-md-10 text-subtitle-1"
                >
                    <div class="mb-4">
                        <span class="font-weight-bold primary--text">
                            Microsoft MSN Publishing
                        </span>
                        is your complete solution to getting content published
                        on MSN.com consistently and repeatedly, without all the
                        usual cost and complication.
                    </div>
                    <p>
                        Using our methods and this new distribution channel, you
                        can tap into billions of overlooked monthly visitors
                        from MSN, Bing, Bing News, Yahoo… even Google too!
                    </p>
                </v-col>
            </v-row>
        </v-container>
        <div class="blueish">
            <v-container>
                <v-row justify="center">
                    <v-col cols="12" lg="9" xl="7" class="py-6 py-md-10">
                        <div class="subheadline text-center py-8">
                            Look At How Many Ways
                            <span class="primary--text">A Single MSN Post</span>
                            Can Drive Traffic To Your Business...
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </div>
        <v-container>
            <v-row justify="center">
                <v-col
                    cols="12"
                    lg="9"
                    xl="7"
                    class="pt-6 pt-md-10 text-subtitle-1"
                >
                    <v-img
                        class="mb-4"
                        src="@/assets/img/clientcabin/msn/11.png"
                    />
                    <div class="text-center text-h6 font-weight-bold px-10">
                        Any
                        <span class="text-decoration-underline">ONE</span>
                        Of These Traffic Streams Can
                        <span class="primary--text">Drive Sales & Profits</span>
                        And Our Strategy Gives You The Opportunity To Leverage
                        Them All!
                    </div>
                </v-col>
            </v-row>
        </v-container>

        <v-container>
            <v-row justify="center">
                <v-col cols="12" lg="9" xl="7" class="py-6 py-md-10">
                    <p class="text-center text-h5">
                        Select Your Microsoft MSN Publishing Package Below:
                    </p>
                    <p class="text-center font-italic">
                        (bigger packages give you bigger savings)
                    </p>
                    <v-container class="clientcabin-pricing">
                        <v-row>
                            <v-col cols="12" md="6">
                                <v-card elevation="10" class="rounded-lg">
                                    <v-img
                                        class="black--text align-center grey lighten-4"
                                        min-height="160px"
                                    >
                                        <div
                                            class="text-center option-title pa-4 mt-4"
                                        >
                                            Option 1
                                            <span class="option-plan">
                                                Plan 1
                                            </span>
                                            <br />
                                            <b>Single Purchase @</b>
                                        </div>
                                    </v-img>
                                    <div class="price text-center">
                                        {{ reseller_client_msn_price }}
                                    </div>
                                    <v-card-text class="text-center">
                                        <v-btn
                                            x-large
                                            color="primary white--text"
                                            :block="
                                                $vuetify.breakpoint.smAndDown
                                            "
                                            class="px-10 py-8 text-h6 rounded-lg"
                                            @click="onOrder('single')"
                                        >
                                            Add to cart
                                        </v-btn>
                                    </v-card-text>
                                    <v-card-text class="pt-0 text-center">
                                        <v-icon class="mr-1">
                                            $vuetify.icons.secure
                                        </v-icon>
                                        <v-icon large class="mr-1">
                                            $vuetify.icons.mastercard
                                        </v-icon>
                                        <v-icon large class="mr-1">
                                            $vuetify.icons.amex
                                        </v-icon>
                                        <v-icon large class="mr-1">
                                            $vuetify.icons.visa
                                        </v-icon>
                                        <v-icon large>
                                            $vuetify.icons.paypal
                                        </v-icon>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-card elevation="10" class="rounded-lg">
                                    <v-img
                                        class="black--text align-center grey lighten-4"
                                        min-height="160px"
                                    >
                                        <div
                                            class="text-center mt-n5 primary white--text text-button"
                                        >
                                            Most Popular
                                        </div>
                                        <div
                                            class="text-center option-title pa-4"
                                        >
                                            Option 2
                                            <span class="option-plan">
                                                Plan 2
                                            </span>
                                            <br />
                                            <b>Monthly Purchase @</b>
                                        </div>
                                    </v-img>
                                    <div class="price text-center">
                                        {{
                                            reseller_client_recurring_msn_price
                                        }}
                                    </div>
                                    <v-card-text class="text-center">
                                        <v-btn
                                            x-large
                                            color="primary white--text"
                                            :block="
                                                $vuetify.breakpoint.smAndDown
                                            "
                                            class="px-10 py-8 text-h6 rounded-lg"
                                            @click="onOrder('recurring')"
                                        >
                                            Add to cart
                                        </v-btn>
                                    </v-card-text>
                                    <v-card-text class="pt-0 text-center">
                                        <v-icon class="mr-1">
                                            $vuetify.icons.secure
                                        </v-icon>
                                        <v-icon large class="mr-1">
                                            $vuetify.icons.mastercard
                                        </v-icon>
                                        <v-icon large class="mr-1">
                                            $vuetify.icons.amex
                                        </v-icon>
                                        <v-icon large class="mr-1">
                                            $vuetify.icons.visa
                                        </v-icon>
                                        <v-icon large>
                                            $vuetify.icons.paypal
                                        </v-icon>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

import { InjectReactive } from '@/utils/decorators';

import type { ClientCabin } from '@/types/ClientCabin';

@Component
export default class Msn extends Vue {
    endpoint = '/msn';

    @InjectReactive({
        from: 'options',
        default() {
            return false;
        }
    })
    options!: Partial<ClientCabin> | null;

    get reseller_client_msn_price() {
        return this.format(this.options?.reseller_client_msn_price);
    }

    get reseller_client_recurring_msn_price() {
        return `${this.format(
            this.options?.reseller_client_recurring_msn_price
        )}/mo`;
    }

    format(price?: number) {
        if (price) {
            return new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: this.options?.currency || 'USD',
                maximumFractionDigits: 0
            }).format(price);
        }

        return price;
    }

    onOrder(product: 'single' | 'recurring') {
        this.$http
            .post(this.endpoint, {
                [product]: 1
            })
            .then(({ data }) => {
                if (data.data.success) {
                    this.onSuccess(data.data);
                }
            });
    }

    onSuccess({ redirect }: { redirect?: string }) {
        if (redirect) {
            // payment page
            window.location.href = redirect;
        }
    }
}
</script>

<style lang="scss" scoped>
.clientcabin-msn::v-deep {
    color: $black;
    background-color: $white;

    .option-title {
        font-size: 1.25rem;
        line-height: 1.8em;
        text-transform: uppercase;

        .option-plan {
            border: 2px solid grey;
            padding: 4px 8px;
            margin-left: 8px;
            font-weight: 600;
        }
    }

    ul {
        list-style: none;
        padding-left: 12px;

        li {
            line-height: 2.5em;
            display: flex;
            align-items: center;

            &:before {
                color: $white;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                width: 24px;
                height: 24px;
                border-radius: 1rem;
                margin-right: 0.7rem;
                content: '✓';
                background: $clientcabin-apple;
                font-weight: 900;
            }
        }
    }

    .quote {
        font-style: italic;

        &:before {
            content: '\201C';
            font-size: 175%;
            line-height: 0;
            position: relative;
            top: 0.85rem;
            font-family: sans-serif;
        }

        &:after {
            content: '\201D';
            font-size: 175%;
            line-height: 0;
            position: relative;
            top: 0.85rem;
            font-family: sans-serif;
        }
    }

    .price {
        font-weight: 700;
        font-size: 67px;
        letter-spacing: -1px;
        text-align: center;
        padding: 25px 0;
        color: $black;
    }
}
</style>
